import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import Responsive from './Responsive';
import lockerIcon from '../../resources/images/svg/lock-24px.svg';
import Button from './Button';
import { AutoSizer, List } from 'react-virtualized';
import Select from 'react-select';
import { MdLocalParking } from 'react-icons/md';
import { RiCloseFill } from 'react-icons/ri';
import { HiMenu } from 'react-icons/hi';
import { NavLink } from 'react-router-dom';
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { BsDot } from 'react-icons/bs';
import Logo from './Logo';
import {
  ImEnter
} from 'react-icons/im';
import {
  RiDoorLine
} from 'react-icons/ri';
import {
  FiSettings,
  FiUser,
  FiBriefcase,
  FiCalendar,
  FiDollarSign,
  FiBarChart,
  FiShoppingCart,
  FiMonitor,
  FiEdit
} from 'react-icons/fi';
import {FaMobileAlt} from 'react-icons/fa';
import {BsBellFill, BsBell} from 'react-icons/bs';
import logo from '../../resources/images/logo/logo_12(white).png';
import doorIcon from '../../resources/images/svg/doorSvg.svg';
import { Ring } from 'react-awesome-spinners';
import { returnTimeToString, timeValueToDate3, timeValueToDate4, timeValueToDate5, timeValueToDateTime } from '../../lib/commonFunc/commonFunc';
import { lt } from 'date-fns/locale';
import club from '../../modules/clubs/club';

const NotificationModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const NotificationBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;
  height: 95vh;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const NotificationTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const NotificationContentBox = styled.div`
  width: 800px;
  height: 100%;
  overflow: auto;
  & ::-webkit-scrollbar {
    width: 10px;
  }
  & ::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  & ::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const HeaderBlock = styled.div`
  width: 100%;
  min-width: 768px;
  height: 50px;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  position: absolute;
  display: flex;
  justify-content: flex-end;
`;
const MobileHeaderBlock = styled.div`
  display: none;
  width: 100%;
  height: 50px;
  position: fixed;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  z-index: 999;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  & svg {
    font-size: 2rem;
    color: #141414;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    display: flex;
  }
`;

const RightBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & button {
    margin-left: 20px;
  }
`;

const activeStyle = {
  color: palette.webCyan[5],
  fontWeight: 'bold',
};
const StyledMenu = css`
  color: ${palette.webBlack[9]};
  font-size: 1rem;
  display: flex;
  align-items: center;
  width: 130px;
  @media screen and (max-width: 1600px) {
    width: 110px;
    font-size: 0.8rem;
  }
`;

const LogoImg = styled.img`
  width: auto;
  height: 100%;
  padding: 13px 13px 13px 2px;
`;
const Wrapper = styled(Responsive)`
  height: 10rem;
  min-width: 100px;
  width: calc(100% - 240px);
  padding: 0 3rem;

  @media screen and (max-width: 1600px) {
    width: calc(100% - 200px);
  }

  @media screen and (max-width: 768px) {
    justify-content: flex-end;
    margin-left: 10%;
    display: none;
  }
`;

const AccountInfoBlock = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  .logo {
    font-size: 1.125rem;
    font-weight: 800;
    letter-spacing: 2px;
  }
  .right {
    display: flex;
    align-items: center;
  }
`;

const UserInfo = styled.div`
  font-weight: 800;
  margin-right: 1rem;
`;

const History = styled.div`
  color: ${palette.white};
  font-size: 12pt;
  display: flex;
  margin-top: 2rem;
  align-items: center;
  svg {
    color: ${palette.white};
    font-size: 16pt;
  }
  div {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
`;

const MobileMenuBox = styled.div`
  position: relative;
  width: 240px;
  height: 100%;
  display: flex;
  position: fixed;
  min-width: 150px;
  flex-direction: column;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  background: white;
  z-index: 9999;
  left: -240px;
  @media screen and (max-width: 1600px) {
    width: 200px;
  }
  @media screen and (min-width: 769px) {
    display: none;
  }
  transition: .3s ease-in-out;

  &.active {
    left: 0;
  }
`;
const StyledUl = styled.ul`
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  align-items: center;
  
  & svg.close {
    position: absolute;
    font-size: 1.3rem;
    color: #000;
    top: 15px;
    right: 15px;
  }
`;
const LockerIcon = styled.img`
  width: 16.8px;
  height: 16.8px;
`;

const StyledLi = styled.li`
  margin-top: 1.25rem;
`;
const StyledLink = styled(NavLink)`
  ${StyledMenu}

  span {
    margin-right: 10px;
    font-size: 1.05rem;
    @media screen and (max-width: 1600px) {
      font-size: 0.9rem;
    }
  }
`;

const HeaderRightBox = styled.div`
  display: flex;
`;

const BtnDoorOpen = styled.button`
  border: 0;
  border-radius: .2rem;
  font-weight: bold;
  cursor: pointer;
  padding: 0 10px;
`;

const BtnLogin = styled.button`
  padding: 5px 10px;
  border: 0;
  border-radius: .15em;
  background-color: #141414;
  color: #fff;
  font-size: .9rem;
  font-weight: bold;
  cursor: pointer;
`;

const HeaderIconBox = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 50px;
`;

const NotificationIconBox = styled.li`
  position: relative;

  & svg {
    color: #141414;
    font-size: 1.65rem;
    padding-top: 2px;
    cursor: pointer;
  }
`;

const DoorIconBox = styled.li`
  & svg {
    color: #141414;
    font-size: 2.1rem;
    padding-top: 2px;
    cursor: pointer;
    margin-right: 10px;
  }
`;

const Count = styled.div`
  background-color: ${palette.webCyan[5]};
  font-size: 1rem;
  color: #fff;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  font-size: .75rem;
  position: absolute;
  font-weight: bold;
  top: 0;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
`;

const NotificationList = styled.ul`
  position: absolute;
  top: calc(100% + 22px);
  right: -8px;
  padding: 5px 15px;
  background-color: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: .3rem;
  z-index: 99;
  width: 260px;
  height: fit-content;

  & svg {
    position: absolute;
    top: -20px;
    right: 10px;
    color: #fff;
    font-size: 1.4rem;
  }
`;

const NotificationListItem = styled.li`
  color: #4d4d4d;
  font-size: .95rem;
  padding: 10px 0;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, .2);

  &:nth-last-child(1) {
    border-bottom: 0;
  }

  & .content {
    width: 100%;
    margin-bottom: 10px;

    & span {
      color: ${palette.webCyan[5]};
    }
  }

  & .registTime {  
    width: 100%;
    text-align: right;
    color: rgba(0, 0, 0, .5);
    font-size: .8rem;
  }
`;

const NotificationItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: .9rem;
  line-height: 140%;
  color: #4d4d4d;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  height: 100%;

  &.delete {
    cursor: pointer;
  }

  & .content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 75%;
    padding-left: 50px;
    text-align: left;

    &.none-padding {
      padding-left: 20px;
    }
  }

  & .registTime {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    text-align: right;
    padding-right: 20px;
    padding-left: 20px;
  }

  & span {
    color: ${palette.webCyan[5]};
  }
`;

const NotificationBtnBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  border-top: 1px solid rgba(0, 0, 0, .1);
`;

const BtnNotificationDelete = styled.button`
  background-color: #fff;
  color: #4d4d4d;
  font-size: .9rem;
  padding: 7px 45px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .2rem;
`
const BtnNotificationClose = styled.button`
  background-color: ${palette.webGray[5]};
  color: #fff;
  font-size: .9rem;
  padding: 7px 45px;
  cursor: pointer;
  border: 1px ${palette.webGray[5]};
  border-radius: .2rem;
`

const NoticeDeleteIcon = styled.div`

  position: absolute;
  left: 20px;
  padding-top: 5px;

    &.active{
        & svg {
            color: #EC1B23;
            font-size: 1.5rem;
        }
    }
    & svg {
        color: rgba(0, 0, 0, .2);
        font-size: 1.5rem;
    }
`;

const CheckDot = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 2rem;
    color: ${palette.webCyan[5]};
`;

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderBottom: '1px dotted pink',
    color: state.selectProps.menuColor,
    padding: 20,
    'z-index': 500,
  }),
  indicatorSeparator: (provided, state) => ({
    backgroundColor: 'none',
  }),
  indicatorContainer: (provided, state) => ({
    color: '#4d4d4d',
  }),
  input: () => ({
    curosr: 'pointer',
    color: '#4d4d4d',
  }),
  control: (_, { selectProps: { width } }) => ({
    width: width,
    display: 'flex',
    color: '#4d4d4d',
    'z-index': 500,
    curosr: 'pointer',
  }),
  placeholder: () => ({
    cursor: 'pointer',
    color: '#4d4d4d',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    const color = '#ff';

    return { ...provided, opacity, transition, color };
  },
};

const Header = ({ 
  user, 
  onLogout, 
  onSelect, 
  myClubs, 
  defaultClub, 
  newNoticeList, 
  noticeLoading, 
  onLoadNotice, 
  noticeList, 
  onClickNotification,
  deleteNoticeList,
  setDeleteNoticeList,
  onDeleteNotice,
  noticeDeleteMode,
  setNoticeDeleteMode,
  isAllDelete,
  setIsAllDelete,
  onOpenDoor,
  openDoorResult,
  onInitDoorRes,
  patchTest
 }) => {
  const {
      currentClub
    } = useSelector(({ club }) => ({
      currentClub: club.defaultClub,
  }));

  const navigation = [
    /* {
      name: 'attendance',
      text: '출석관리',
      url: '/attendance',
    },*/
    {
      name: 'memeber',
      text: '회원',
      url: '/member',
      icon: function () {
        return <FiUser />;
      },
    },
    {
      name: 'attend',
      text: '출입 기록',
      url: '/attend',
      icon: function () {
        return <ImEnter />;
      },
    },
    {
      name: 'locker',
      text: '락커',
      url: '/locker',
      icon: function () {
        return <LockerIcon src={lockerIcon} />
      },
    },
    {
      name: 'lesson',
      text: '레슨',
      url: '/lesson',
      icon: function () {
        return <FiCalendar />
      },
    },
    {
      name: 'mobileProduct',
      text: '모바일 판매',
      url: '/mobileProduct',
      icon: function () {
        return <FiShoppingCart />;
      },
    },
    {
      name: 'mobile',
      text: '모바일 홍보',
      url: '/mobile',
      icon: function () {
        return <FaMobileAlt />;
      },
    },
    {
      name: 'sales',
      text: '매출',
      url: '/sales',
      icon: function () {
        return <FiDollarSign />;
      },
    },
    {
      name: 'statistics',
      text: '통계',
      url: '/statistics',
      icon: function () {
        return <FiBarChart />;
      },
    },
    {
      name: 'point',
      text: '포인트',
      url: '/point',
      icon: function () {
        return <MdLocalParking />;
      },
    },
    {
      name: 'prescription',
      text: '기록 & 처방',
      url: '/prescription',
      icon: function () {
        return <FiEdit />;
      },
    },
    {
      name: 'configuration',
      text: '설정',
      url: '/configuration/center',
      icon: function () {
        return <FiSettings />;
      },
    },
  ];

  const [notificationModal, setNotificationModal] = useState(false)
  const [doorModal, setDoorModal] = useState(false)
  const [mobileMenuBoxClass, setMobileMenuBoxClass] = useState("");
  const [selectedOption, setSelectedOption] = useState("전체");
  const [filteredNoticeList, setFilteredNoticeList] = useState([])
  const doorModalRef = useRef(null);

  let notifiNum = 0

  const options = [
    {label: '전체', value: '전체'},
    {label: '레슨 예약', value: '레슨 예약'},
    {label: '레슨 예약 취소', value: '레슨 예약 취소'},
    {label: '모바일 구매 - 신규', value: '모바일 구매 - 신규'},
    {label: '모바일 구매 - 재등록', value: '모바일 구매 - 재등록'},
    {label: '모바일 구매 취소', value: '모바일 구매 취소'}
  ]

  useEffect(() => {
    if(noticeList && noticeList.length > 0) {
      if(selectedOption === "전체") {
        let temp =[]
        for (let i = 0; i < noticeList.length; i++) {
          temp.push(noticeList[i])
        }
        setFilteredNoticeList(temp)
      } else {
        let temp = []
        for (let i = 0; i < noticeList.length; i++) {
          if(selectedOption === noticeList[i].title) {
            temp.push(noticeList[i])
          }
        }
        setFilteredNoticeList(temp)
      }
    }
  }, [noticeList, selectedOption])

  const notificationListRenderer = useCallback(
    ({ index, key, style }) => {
      const notification = filteredNoticeList[index];
      return (
        <NotificationItem
          style={style}
          key={index}
        >
            <li className="content none-padding" style={{position: 'relative', display: 'flex', flex: 1}}>
              {notification.clubCheck === 0 &&
                <CheckDot><BsDot/></CheckDot>
              }
              <span style={{marginRight: '10px'}}>{"<"}{notification.title}{">"}</span><span style={{color: "#4d4d4d", flex: 1}}>{notification.contents}</span>
            </li>
            <li className="registTime">{timeValueToDate5(notification.registTime)}</li>
        </NotificationItem>
      );
    },
    [filteredNoticeList, noticeDeleteMode],
  );

  const notificationDeleteListRenderer = useCallback(
    ({ index, key, style }) => {
      const notification = filteredNoticeList[index];
      return (
        <NotificationItem
          style={style}
          key={index}
          className="delete"
          onClick={() => {
            if(deleteNoticeList.includes(notification.memberMessageID)) {
              let tempArr = deleteNoticeList.filter(item => {return item !== notification.memberMessageID})
              setDeleteNoticeList(tempArr)
            } else {
              setDeleteNoticeList([...deleteNoticeList,notification.memberMessageID])
            }
          }}
        >
            <li className="content"  style={{position: 'relative', display: 'flex', flex: 1}}>
              {deleteNoticeList && deleteNoticeList.includes(notification.memberMessageID) ?
                <NoticeDeleteIcon className="active"><MdCheckBox/></NoticeDeleteIcon>
                :
                <NoticeDeleteIcon><MdCheckBoxOutlineBlank /></NoticeDeleteIcon>
              }
              <span style={{marginRight: '5px'}}>{"<"}{notification.title}{">"}</span><span style={{color: "#4d4d4d", flex: 1}}>{notification.contents}</span>
            </li>
            <li className="registTime" style={notification.clubCheck === 0? {fontWeight: 'bold'} : {}}>{timeValueToDate5(notification.registTime)}</li>
        </NotificationItem>
      );
    },
    [filteredNoticeList, noticeDeleteMode, deleteNoticeList],
  );

  // if(consultList && consultList.length > 0) {
  //   let filterConsultList = consultList.filter(item => {return item.clubCheck === 0})
  //   let tempArr = []

  //   for (let i = 0; i < filterConsultList.length; i++) {
  //     if(i < 4) {
  //       tempArr.push(filterConsultList[i])
  //     } else {
  //       break;
  //     }
  //   }

  //   notificationList = tempArr
  //   notifiNum = consultList.filter(item => {return item.clubCheck === 0}).length
  // }
  
  const handleClickOutside = (event) => {
    if (doorModalRef.current && !doorModalRef.current.contains(event.target)) {
      setDoorModal(false);
      onInitDoorRes()
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  console.log('myClubs : ', myClubs)
  return (
    <>
      <HeaderBlock>
        <Wrapper>
          <AccountInfoBlock>
            <Select
              options={myClubs && myClubs.length > 0 ? myClubs : undefined}
              onChange={onSelect}
              defaultValue={defaultClub ? defaultClub.clubID : undefined}
              styles={customStyles}
              isSearchable={false}
              width="220px"
              aria-labelledby="select"
              placeholder={defaultClub ? defaultClub.name : ''}
            />
            
            <HeaderRightBox>
              {user ? (
                <div className="right">
                  <HeaderIconBox>
                    {currentClub && currentClub.attendActive === 1 &&
                      <DoorIconBox>
                        <img style={{marginRight: '15px', height: '30px', width: 'auto', cursor: 'pointer', paddingTop: '2px'}} src={doorIcon} onClick={() => setDoorModal(true)}/>
                        {doorModal &&
                          <div ref={doorModalRef} style={{width:'230px', position: 'absolute', backgroundColor: '#fff', borderRadius: '.2rem', padding: '5px', right: '210px', top: '110%', zIndex: '999', boxShadow: '0px 5px 5px 0px rgba(0, 0, 0, 0.1)'}}>
                            {currentClub && currentClub.clubDoorInfos && currentClub.clubDoorInfos.length > 0 && currentClub.clubDoorInfos.map((door, idx) => {
                              return(
                                <div key={idx} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '5px'}}>
                                  <div>{door.name}</div>
                                  <button style={{backgroundColor: '#fff', border: '1px solid rgba(0, 0, 0, .2)', padding: '2px 5px', borderRadius: '.2rem', cursor: 'pointer', color: '#4d4d4d'}} key={idx} onClick={() => {
                                    onOpenDoor(door.doorid)
                                    onInitDoorRes()
                                  }}>문열기</button>
                                </div>
                              )
                            })}
                            {openDoorResult && openDoorResult === 1 && <div style={{padding: '10px', fontSize: '.95rem', color: palette.webCyan[5], textAlign: 'center', width: '100%'}}>문이 열렸습니다.</div>}
                          </div>
                        }
                      </DoorIconBox>
                    }
                    <NotificationIconBox>
                      <BsBell onClick={() => {
                        onLoadNotice()
                        setNotificationModal(true)
                      }}/>
                      {newNoticeList && newNoticeList.length > 0 && <Count onClick={() => {
                        onLoadNotice()
                        setNotificationModal(true)
                      }}>{newNoticeList.length}</Count>}
                    </NotificationIconBox>
                  </HeaderIconBox>
                  <BtnLogin onClick={onLogout}>로그아웃</BtnLogin>
                </div>
              ) : (
                <div className="right">
                  <BtnLogin to="/login">로그인</BtnLogin>
                </div>
              )}
            </HeaderRightBox>
          </AccountInfoBlock>
        </Wrapper>
      </HeaderBlock>
      <MobileHeaderBlock>
        <HiMenu onClick = {() => {setMobileMenuBoxClass("active")}}/>
        <RightBox>
          <Select
            options={myClubs && myClubs.length > 0 ? myClubs : undefined}
            onChange={onSelect}
            defaultValue={defaultClub ? defaultClub.clubID : undefined}
            styles={customStyles}
            isSearchable={false}
            width="220px"
            aria-labelledby="select"
            placeholder={defaultClub ? defaultClub.name : ''}
          />

          {user ? (
            <div className="right">
              <UserInfo></UserInfo>
              <BtnLogin onClick={onLogout}>로그아웃</BtnLogin>
            </div>
          ) : (
            <div className="right">
              <BtnLogin to="/login">로그인</BtnLogin>
            </div>
          )}
        </RightBox>
      </MobileHeaderBlock>

      <MobileMenuBox className={mobileMenuBoxClass}>
        <Logo />
        <StyledUl>
          <RiCloseFill className="close" onClick={() => {setMobileMenuBoxClass(false)}}/>
          {navigation.map((menu) => {
            if (!currentClub) return false
            if (currentClub && currentClub.pointActive == 0 && menu.name == 'point') return false
            if (currentClub && currentClub.attendActive == 0 && menu.name == 'attend') return false

            return(
              <StyledLi key={menu.name} style={{position: 'relative'}}>
                <StyledLink to={menu.url} activeStyle={activeStyle}>
                  <span className={menu.text && menu.text=="locker" ? "active" : ""}>{menu.icon()}</span>
                  <span>{menu.text}</span>
                </StyledLink>
              </StyledLi>
            )
          })}
{/*           
          {navigation.map((menu) => (
            (currentClub && currentClub.pointActive == 0 && menu.name == 'point')?
            ""
            :
            (currentClub && currentClub.attendActive == 0 && menu.name == 'attend')?
            ""
            :
            ((!mobileProductView || !newIconEndTime || !testClubID) && menu.name == 'mobileProduct') || ((currentClub && currentClub.clubID != testClubID && menu.name == 'mobileProduct') || (mobileProductView === 0 && menu.name == 'mobileProduct'))?
            ""
            :
            <StyledLi key={menu.name} style={{position: 'relative'}}>
              <StyledLink to={menu.url} activeStyle={activeStyle}>
                <span className={menu.text && menu.text=="locker" ? "active" : ""}>{menu.icon()}</span>
                <span>{menu.text}</span>
              </StyledLink>
              {(menu.name === "mobileProduct" && newIconEndTime != 0 && new Date(newIconEndTime).getTime() > new Date().getTime()) ? 
                <span style={{position: 'absolute', left: '90%', padding: '5px 5px 3px 5px', top: 0, backgroundColor: '#FF3E3E', color: '#fff', borderRadius: '4px', fontSize: '.75rem'}}>NEW</span> 
                : 
                ""
              }
            </StyledLi>
          ))} */}
        </StyledUl>
      </MobileMenuBox>
      
      {notificationModal &&
        <NotificationModalWrap>
            <Background />
            <NotificationBox>
                <div style={{width: '100%', display: 'flex', padding: '13px 0', backgroundColor: palette.webCyan[5], color: "#fff"}}>
                  <div style={{width: '75%', fontSize: '.9rem'}}>내용</div>
                  <div style={{width: '25%', fontSize: '.9rem'}}>시간</div>
                </div>
                <div style={{width: '100%', display: 'flex', alignItems: 'flex-start', padding: '10px 15px', borderBottom: '1px solid rgba(0, 0, 0, .1)'}}>
                  <div style={{width: '250px'}}>
                    <Select 
                      options={options}
                      placeholder = "분류"
                      onChange={e => setSelectedOption(e.value)}
                      value={options.find(item => {return selectedOption == item.value})}
                    />
                  </div>
                </div>
                <NotificationContentBox>
                  {noticeLoading ?
                    <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                      <Ring />
                    </div>
                  :
                    filteredNoticeList && filteredNoticeList.length > 0 ?
                      noticeDeleteMode ?
                        <AutoSizer>
                            {({ height, width }) => {
                                return (
                                    <List
                                      rowRenderer={notificationDeleteListRenderer}
                                      rowCount={filteredNoticeList.length}
                                      width={width}
                                      height={height}
                                      rowHeight={50}
                                      list={filteredNoticeList}
                                      overscanRowCount={5}
                                      className="search_list"
                                    />
                                );
                            }}
                        </AutoSizer>
                      :
                        <AutoSizer>
                            {({ height, width }) => {
                                return (
                                    <List
                                      rowRenderer={notificationListRenderer}
                                      rowCount={filteredNoticeList.length}
                                      width={width}
                                      height={height}
                                      rowHeight={50}
                                      list={filteredNoticeList}
                                      overscanRowCount={5}
                                      className="search_list"
                                    />
                                );
                            }}
                        </AutoSizer>
                    :
                    <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: "#4d4d4d"}}>알림이 없습니다.</div>
                  }
                </NotificationContentBox>
                <NotificationBtnBox>
                  {noticeDeleteMode ?
                    <div style={{display: 'flex'}}>
                      <BtnNotificationDelete onClick={
                        () => {
                          if(!isAllDelete) {
                            let tempArr = []
                            noticeList.map(item => {tempArr.push(item.memberMessageID)})
                            setDeleteNoticeList(tempArr)
                            setIsAllDelete(true)
                          } else {
                            setDeleteNoticeList([])
                            setIsAllDelete(false)
                          }
                        }
                      } style={{paddingRight: '30px', paddingLeft: '30px', marginRight:'15px'}}>
                        {isAllDelete ? "전체 해제" : "전체 선택"}
                      </BtnNotificationDelete>
                      <BtnNotificationDelete onClick={() => onDeleteNotice()}>
                        삭제
                      </BtnNotificationDelete>
                    </div>
                  :
                    <BtnNotificationDelete onClick={() => {
                      setNoticeDeleteMode(true)
                    }}>
                      삭제
                    </BtnNotificationDelete>
                  }
                  <BtnNotificationClose onClick={() => {
                      if(noticeDeleteMode) {
                        setNoticeDeleteMode(false)
                        setIsAllDelete(false)
                        setDeleteNoticeList([])
                      } else {
                        setNotificationModal(false)
                      }
                    }}>
                    {noticeDeleteMode ? "취소" : "닫기"}
                  </BtnNotificationClose>
                </NotificationBtnBox>
            </NotificationBox>
        </NotificationModalWrap>
      }
    </>
  );
};

export default React.memo(Header);
